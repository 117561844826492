import Box from '@mui/material/Box'
import React, { ReactElement } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/src/styles.scss'
import { RecordingViewModel } from '../../api/Recording.model'

export type RecordingPlayerProps = {
    recording?: RecordingViewModel
    autoPlay?: boolean
    onError: () => void
}

export default function RecordingPlayer ({
    recording,
    autoPlay,
    onError
}: RecordingPlayerProps): ReactElement {
    const handleError = (event: Event | null, error: Error | null): void => {
        console.log({ event, error, recording })
        onError()
    }

    return (<>
        <Box>
            <AudioPlayer
                autoPlay={autoPlay}
                src={recording?.file}
                showDownloadProgress={true}
                showJumpControls={false}
                showSkipControls={false}
                onError={(e) => handleError(e, null)}
                onPlayError={(e) => handleError(null, e)}
            /></Box>
    </>)
}
