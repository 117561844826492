import React, { FormEvent, ReactElement, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Alert, Checkbox, FormControlLabel, Stack } from '@mui/material'

export type LoginDialogProps = {
    open: boolean
    invalidCredentials: boolean
    onLogin: (username: string, password: string, rememberMe: boolean) => void
    onClose: () => void
}

export default function LoginDialog ({
    open,
    invalidCredentials,
    onLogin,
    onClose
}: LoginDialogProps): ReactElement {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [rememberMe, setRememberMe] = useState<boolean>(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault()

        if (username != null && password != null) {
            onLogin(username, password, rememberMe)
            clear()
        }
    }

    const clear = (): void => {
        setUsername('')
        setPassword('')
        setRememberMe(false)
    }

    const handleClose = (): void => {
        clear()
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Přihlásit se</DialogTitle>
            <form onSubmit={(e) => handleSubmit(e)}>
                <DialogContent>
                    <DialogContentText>
                        Zadejte své přihlašovací údaje, prosím
                    </DialogContentText>
                    <Stack direction="column" spacing={1} sx={{ marginTop: '1rem' }}>
                        {invalidCredentials &&
                            <Alert severity="error">Neplatné přihlašovací údaje</Alert>
                        }

                        <TextField
                            autoFocus
                            label="Přihlašovací jméno"
                            type="email"
                            name="username"
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUsername(event.target.value)
                            }}
                            fullWidth
                            required
                            variant="standard"
                        />
                        <TextField
                            label="Heslo"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value)
                            }}
                            fullWidth
                            required
                            variant="standard"
                        />
                        <FormControlLabel control={<Checkbox
                            value={rememberMe}
                            onChange={(e, checked) => setRememberMe(checked)}
                        />} label="Zapamatovat" />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Zavřít</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary">Přihlásit se</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
