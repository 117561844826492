import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import React, { ReactElement } from 'react'
import { AnswerButtonModel } from '../../model/AnswerButtonState.model'
import { TaxonViewModel } from '../../model/Taxon.model'

type Props = {
    answerButtons: AnswerButtonModel[]
    handleAnswerClick: (bird: TaxonViewModel) => void
}

export default function QuizQuestionAnswerButtons ({
    answerButtons,
    handleAnswerClick
}: Props): ReactElement {
    return (
        <Stack direction="column">
            {answerButtons.map((answerButton) => {
                return <Button
                    onClick={() => { handleAnswerClick(answerButton.bird) }}
                    sx={{
                        marginBottom: '0.5rem',
                        whiteSpace: 'nowrap',
                        cursor: answerButton.state !== 'enabled' ? 'not-allowed' : undefined,
                        pointerEvents: answerButton.state !== 'enabled' ? 'none' : undefined
                    }}
                    color={answerButton.state === 'correct'
                        ? 'success'
                        : answerButton.state === 'incorrect'
                            ? 'error'
                            : 'primary'}
                    variant={(answerButton.state === 'correct' || answerButton.state === 'incorrect') ? 'contained' : 'outlined'}
                    disabled={answerButton.state === 'disabled'}
                    key={answerButton.bird.id}>{answerButton.bird.name}
                </Button>
            })}
        </Stack>
    )
}
