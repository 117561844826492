import React, { ReactElement, useState, useEffect, FormEvent } from 'react'
import { QuizParams } from '../../model/QuizParams.model'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, CardHeader, CardContent, CardActions, Stack, Card } from '@mui/material'
import { SoundType } from '../../api/SoundType.model'
import { QuizMode } from '../../model/QuizMode.model'
import { Box } from '@mui/system'
import { useTaxonsFetch } from '../../hooks/useTaxonsFetch'
import { TaxonViewModel } from '../../model/Taxon.model'
import { QuizType } from '../../model/QuizType.model'
import TaxonsAutocomplete from './TaxonsAutocomplete'
import UserTaxonSelectionSearchDialog from './UserTaxonSelectionSearchDialog'
import { UserTaxonSelectionViewModel } from '../../model/UserTaxonSelection.model'
import UserTaxonSelectionSaveDialog from './UserTaxonSelectionSaveDialog'
import { useLoginContext } from '../../providers/useLoginContext'
import SaveIcon from '@mui/icons-material/Save'
import OpenIcon from '@mui/icons-material/FolderOpen'
import { UserTaxonSelectionInsertRequest } from '../../model/UserTaxonSelectionInsertRequest.model'
import { useUserTaxonSelectionPost } from '../../hooks/useUserTaxonSelectionPost'
import { useSnackbar } from 'notistack'

export type QuizParamsFormProps = {
    initialParams: QuizParams
    onBuild: (params: QuizParams, taxons: TaxonViewModel[]) => void
}

export default function QuizParamsForm ({ initialParams, onBuild }: QuizParamsFormProps): ReactElement {
    const [taxons, setTaxons] = useState<TaxonViewModel[]>(initialParams.taxons)
    const [allTaxons, setAllTaxons] = useState<TaxonViewModel[]>([])
    const [questionCount, setQuestionCount] = useState<number>(initialParams.questionCount)
    const [quizType, setQuizType] = useState<QuizType>(initialParams.quizType)
    const [soundType, setSoundType] = useState<SoundType>('all')
    const [quizMode, setQuizMode] = useState<QuizMode>('education')
    const [userTaxonSelectionSearchOpened, setUserTaxonSelectionSearchOpened] = useState<boolean>(false)
    const [userTaxonSelectionSaveOpened, setUserTaxonSelectionSaveOpened] = useState<boolean>(false)
    const [userTaxonSelectionInsertRequest, setUserTaxonSelectionInsertRequest] = useState<UserTaxonSelectionInsertRequest>()
    const [userTaxonSelectionInsertError, setUserTaxonSelectionInsertError] = useState<string>()
    // const [birdsByLocationSearchOpened, setBirdsByLocationSearchOpened] = useState<boolean>(false)

    const { user } = useLoginContext()
    const { enqueueSnackbar } = useSnackbar()

    const taxonsFetchStatus = useTaxonsFetch(8264, 2)

    const userTaxonSelectionPostStatus = useUserTaxonSelectionPost(userTaxonSelectionInsertRequest)

    useEffect(() => {
        if (taxonsFetchStatus.status === 'loaded') {
            console.log('setAllTaxons', taxonsFetchStatus)
            setAllTaxons(taxonsFetchStatus.value)
        }
    }, [taxonsFetchStatus])

    const handleQuestionCountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setQuestionCount(Number(event.target.value))
    }

    const toggleUserTaxonSelectionSearch = (): void => {
        setUserTaxonSelectionSearchOpened(state => !state)
    }

    const toggleUserTaxonSelectionSave = (): void => {
        setUserTaxonSelectionSaveOpened(state => !state)
    }

    // const toggleBirdsByLocationSearch = (): void => {
    //     setBirdsByLocationSearchOpened(state => !state)
    // }

    // const handleBirdsByLocationSelected = (selectedBirds: TaxonViewModel[]): void => {
    //     setBirds(selectedBirds)
    //     setBirdsByLocationSearchOpened(false)
    // }

    const handleQuizTypeChange = (event: SelectChangeEvent<QuizType>): void => {
        setQuizType(event.target.value as QuizType)
    }

    const handleSoundTypeChange = (event: SelectChangeEvent<SoundType>): void => {
        setSoundType(event.target.value as SoundType)
    }

    const handleQuizModeChange = (event: SelectChangeEvent<QuizMode>): void => {
        setQuizMode(event.target.value as QuizMode)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault()

        onBuild({
            taxons: taxons,
            questionCount: questionCount,
            quizType: quizType,
            soundType: soundType,
            mode: quizMode
        },
            allTaxons)
    }

    const isTaxonsValid = (): boolean => {
        if (taxons.some(taxon => taxon.taxonRank !== 'species' && taxon.taxonRank !== 'subspecies')) {
            return true
        }

        return taxons.length > 1
    }

    const handleUserTaxonSelectionSelected = (userTaxonSelection: UserTaxonSelectionViewModel): void => {
        const selectedTaxons = allTaxons.filter(taxon => userTaxonSelection.taxonIds.includes(taxon.id))

        setTaxons(selectedTaxons)
        setUserTaxonSelectionSearchOpened(false)
    }

    const handleUserTaxonSelectionSaved = (name: string, isPublic: boolean): void => {
        const request: UserTaxonSelectionInsertRequest = {
            languageID: 2,
            placeID: 8264,
            name,
            public: isPublic,
            taxonIds: taxons.map(taxon => taxon.id)
        }

        setUserTaxonSelectionInsertRequest(request)
    }

    useEffect(() => {
        console.log('userTaxonSelectionPostStatus', userTaxonSelectionPostStatus)

        if (userTaxonSelectionPostStatus.status === 'loaded') {
            setUserTaxonSelectionSaveOpened(false)
            setUserTaxonSelectionInsertRequest(undefined)
            enqueueSnackbar('Výběr byl uložen', { variant: 'success' })
        } else if (userTaxonSelectionPostStatus.status === 'error') {
            setUserTaxonSelectionInsertError('Nepodařilo se uložit výběr')
            setUserTaxonSelectionInsertRequest(undefined)
        }
    }, [userTaxonSelectionPostStatus])

    return (<>
        <form onSubmit={(e) => handleSubmit(e)}>
            <Card>
                <CardHeader title="Vytvořit kvíz" />

                <CardContent>
                    <Box sx={{ marginTop: '1rem' }}>
                        <FormControl variant="outlined">
                            <InputLabel id="quiz-type-label">Typ kvízu</InputLabel>
                            <Select
                                labelId="quiz-type-label"
                                value={quizType}
                                onChange={handleQuizTypeChange}
                                label="Typ kvízu">
                                <MenuItem value={'sound'}>Zvuky</MenuItem>
                                <MenuItem value={'photo'}>Fotografie</MenuItem>
                                <MenuItem value={'both'}>Zvuky + Fotografie</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ marginTop: '1rem' }}>
                        {/* <BirdsByLocationSearchModal
                        open={birdsByLocationSearchOpened}
                        onBirdsSelected={handleBirdsByLocationSelected}
                        onClose={toggleBirdsByLocationSearch} />

                        <Button onClick={toggleBirdsByLocationSearch}>Vybrat druhy podle lokality (AVIF)</Button> */}

                        <TaxonsAutocomplete
                            allTaxons={allTaxons}
                            taxons={taxons}
                            loading={taxonsFetchStatus.status === 'loading'}
                            onTaxonsChange={setTaxons} />

                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Button
                                onClick={toggleUserTaxonSelectionSearch}
                                startIcon={<OpenIcon />}
                            >Použít uložený výběr</Button>
                            <UserTaxonSelectionSearchDialog
                                open={userTaxonSelectionSearchOpened}
                                onClose={toggleUserTaxonSelectionSearch}
                                onSelect={handleUserTaxonSelectionSelected}
                            ></UserTaxonSelectionSearchDialog>

                            {user != null && <>
                                <Button
                                    onClick={toggleUserTaxonSelectionSave}
                                    disabled={taxons.length < 2}
                                    startIcon={<SaveIcon />}
                                >Uložit výběr</Button>
                            </>}
                        </Stack>
                    </Box>

                    {quizType !== 'photo' &&
                        <Box sx={{ marginTop: '1rem' }}>
                            <FormControl variant="outlined">
                                <InputLabel id="sound-type-label">Typ zvuku</InputLabel>
                                <Select
                                    labelId="sound-type-label"
                                    value={soundType}
                                    onChange={handleSoundTypeChange}
                                    label="Typ zvuku">
                                    <MenuItem value={'all'}>Všechny zvuky</MenuItem>
                                    <MenuItem value={'song'}>Pouze zpěv</MenuItem>
                                    <MenuItem value={'call'}>Pouze volání</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    }

                    <Box sx={{ marginTop: '1rem' }}>
                        <FormControl variant="outlined" sx={{ marginTop: '1rem' }}>
                            <InputLabel id="quiz-mode-label">Režim kvízu</InputLabel>
                            <Select
                                labelId="quiz-mode-label"
                                value={quizMode}
                                onChange={handleQuizModeChange}
                                label="Režim kvízu">
                                <MenuItem value={'education'}>Výukový (více pokusů na každou otázku)</MenuItem>
                                <MenuItem value={'competition'}>Soutěžní (jeden pokus na každou otázku )</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ marginTop: '1rem' }}>
                        <TextField
                            label="Počet otázek"
                            type="number"
                            value={questionCount}
                            variant="outlined"
                            onChange={handleQuestionCountChange} />
                    </Box>
                </CardContent>
                <CardActions>
                    <Stack sx={{ width: '100%' }}
                        direction="row"
                        justifyContent="flex-end">
                        <Button
                            type="submit"
                            disabled={!isTaxonsValid()}
                            variant="contained"
                            color="primary">Vytvořit</Button>
                    </Stack>
                </CardActions>
            </Card>
        </form>
        <UserTaxonSelectionSaveDialog
            open={userTaxonSelectionSaveOpened}
            error={userTaxonSelectionInsertError}
            onSave={handleUserTaxonSelectionSaved}
            onClose={toggleUserTaxonSelectionSave}
        ></UserTaxonSelectionSaveDialog>
    </>)
}
