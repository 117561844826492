import React, { ReactElement, useEffect, useState } from 'react'
import { QuizSummary } from '../../model/QuizSummary.model'
import QuizResultsTable from './QuizResultsTable'
import { Box } from '@mui/system'
import { Button, Card, Stack } from '@mui/material'
import Gauge from '../Common/Gauge'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { QuizParams } from '../../model/QuizParams.model'
import { useUserQuizResultPost } from '../../hooks/useUserQuizResultPost'
import { UserQuizResultInsertRequest } from '../../model/UserQuizResultInsertRequest.model'
import { useLoginContext } from '../../providers/useLoginContext'

export type QuizResultsProps = {
    summary: QuizSummary
    params: QuizParams
    onRepeatQuiz: () => void
    onNewQuiz: () => void
}

export default function QuizResults ({
    summary,
    params,
    onRepeatQuiz,
    onNewQuiz
}: QuizResultsProps): ReactElement {
    const [userQuizResultInsertRequest, setUserQuizResultInsertRequest] = useState<UserQuizResultInsertRequest>()

    const { user } = useLoginContext()

    const correctCount = summary.answers.filter(answer => answer.correct).length
    const score = Math.floor(correctCount * 100 / summary.answers.length)

    useUserQuizResultPost(userQuizResultInsertRequest)

    useEffect(() => {
        if (user != null) {
            setUserQuizResultInsertRequest({
                placeID: 8264,
                quizParams: {
                    taxonIDs: params.taxons.map(bird => bird.id),
                    questionCount: params.questionCount,
                    mode: params.mode,
                    quizType: params.quizType,
                    soundType: params.soundType
                },
                result: score,
                answers: summary.answers.map(answer => {
                    return {
                        questionTaxonID: answer.bird.id,
                        answerTaxonID: answer.pickedBirds?.[0]?.id ?? null,
                        otherAnswerTaxonIDs: answer.pickedBirds.slice(1).map(bird => bird.id)
                    }
                })
            })
        }
    }, [summary])

    return (<>
        <Card>
            <CardHeader title="Výsledky kvízu" />

            <CardContent>
                <Stack direction="row" alignItems={'center'} spacing={2} sx={{
                    marginBottom: '1rem'
                }}>
                    <Box>
                        <Gauge value={score} />
                    </Box>
                    <Box sx={{
                        marginBottom: '1rem'
                    }}>Správné odpovědi: {correctCount} ze {summary.answers.length}</Box>
                </Stack>

                <QuizResultsTable answers={summary.answers}></QuizResultsTable>
            </CardContent>

            <CardActions>
                <Stack sx={{ width: '100%' }}
                    direction="row"
                    justifyContent="flex-end">
                    <Button
                        sx={{
                            marginLeft: '1rem'
                        }}
                        onClick={onRepeatQuiz}
                        variant="contained"
                        color="primary">Spustit znovu</Button>
                    <Button
                        sx={{
                            marginLeft: '1rem'
                        }}
                        onClick={onNewQuiz}
                        variant="contained"
                        color="primary">Vytvořit nový kvíz</Button>
                </Stack>
            </CardActions>
        </Card>
    </>)
}
