import React, { ReactElement, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Checkbox, FormControlLabel, List, ListItemButton, ListItemText, Stack } from '@mui/material'
import { UserTaxonSelectionViewModel } from '../../model/UserTaxonSelection.model'
import { useUserTaxonSelectionsFetch } from '../../hooks/useUserTaxonSelectionsFetch'
import { useLoginContext } from '../../providers/useLoginContext'

export type UserTaxonSelectionSearchDialogProps = {
    open: boolean
    onSelect: (userTaxonSelection: UserTaxonSelectionViewModel) => void
    onClose: () => void
}

export default function UserTaxonSelectionSearchDialog ({
    open,
    onSelect,
    onClose
}: UserTaxonSelectionSearchDialogProps): ReactElement {
    const [query, setQuery] = useState<string>('')
    const [onlyMine, setOnlyMine] = useState<boolean>(false)

    const { user } = useLoginContext()

    const fetchStatus = useUserTaxonSelectionsFetch(8264, 2, open ? query : undefined, open ? onlyMine : undefined)

    const clear = (): void => {
        setQuery('')
        setOnlyMine(false)
    }

    const handleClose = (): void => {
        clear()
        onClose()
    }

    const handleSelect = (userTaxonSelection: UserTaxonSelectionViewModel): void => {
        onSelect(userTaxonSelection)
        clear()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth>
            <DialogTitle>Použít uložený výběr</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1} sx={{ marginTop: '1rem' }}>
                    {user != null &&
                        <FormControlLabel control={<Checkbox
                            value={onlyMine}
                            onChange={(e, checked) => setOnlyMine(checked)}
                        />} label="Pouze moje výběry" />
                    }

                    <List sx={{ height: '200px' }}>
                        {fetchStatus.status === 'loaded' && fetchStatus.value.map(uts =>
                            <ListItemButton
                                key={uts.id}
                                onClick={() => handleSelect(uts)}
                            >
                                <ListItemText primary={uts.name} />
                            </ListItemButton>
                        )}
                    </List>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zavřít</Button>
            </DialogActions>
        </Dialog >
    )
}
