import React, { createContext, ReactElement, useEffect, useState } from 'react'
import LoginDialog from '../components/Login/LoginDialog'
import { useLoginFetch } from '../hooks/useLoginFetch'
import { useLogoutFetch } from '../hooks/useLogoutFetch'
import { useUserFetch } from '../hooks/useUserFetch'
import { LoginRequest } from '../model/LoginRequest.model'
import { User } from '../model/User.model'
import { WithChildren } from '../shared/WithChildren'

export type LoginContextValue = {
    user: User | null
    showLoginForm: () => void
    logout: () => void
}

export const LoginContext = createContext<LoginContextValue>({
    user: null,
    showLoginForm: () => { },
    logout: () => { }
})

export type LoginProviderProps = WithChildren<{}>

export type LogoutRequest = {}

export default function LoginProvider ({ children }: LoginProviderProps): ReactElement {
    const [user, setUser] = useState<User | null>(null)
    const [showLoginDialog, setShowLoginDialog] = useState<boolean>(false)
    const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false)
    const [loginRequest, setLoginRequest] = useState<LoginRequest>()
    const [logoutRequest, setLogoutRequest] = useState<LogoutRequest>()

    const userFetchStatus = useUserFetch()
    const loginFetchStatus = useLoginFetch(loginRequest)
    const logoutFetchStatus = useLogoutFetch(logoutRequest)

    const showLoginForm = (): void => {
        setShowLoginDialog(true)
    }

    const logout = (): void => {
        setLogoutRequest({})
    }

    const handleLogin = (userName: string, password: string, rememberMe: boolean): void => {
        setLoginRequest({
            userName,
            password,
            rememberMe
        })
    }

    useEffect(() => {
        console.log(userFetchStatus)

        if (userFetchStatus.status === 'loaded' && userFetchStatus.value.isAuthenticated && userFetchStatus.value.user != null) {
            setUser(userFetchStatus.value.user)
        }
    }, [userFetchStatus])

    useEffect(() => {
        if (loginFetchStatus.status === 'loaded') {
            setInvalidCredentials(false)
            setShowLoginDialog(false)
            setUser(loginFetchStatus.value)
            setLoginRequest(undefined)

            return
        }

        if (loginFetchStatus.status === 'error') {
            setInvalidCredentials(true)
            setLoginRequest(undefined)
        }
    }, [loginFetchStatus])

    useEffect(() => {
        if (logoutFetchStatus.status === 'loaded') {
            setUser(null)
            setLogoutRequest(undefined)
        } else if (logoutFetchStatus.status === 'error') {
            setLogoutRequest(undefined)
        }
    }, [logoutFetchStatus])

    const handleClose = (): void => {
        setInvalidCredentials(false)
        setShowLoginDialog(false)
    }

    const contextValue: LoginContextValue = {
        user,
        showLoginForm,
        logout
    }

    return (
        <LoginContext.Provider value={contextValue}>
            <LoginDialog
                open={showLoginDialog}
                invalidCredentials={invalidCredentials}
                onLogin={handleLogin}
                onClose={handleClose} />
            {children}
        </LoginContext.Provider>
    )
}
