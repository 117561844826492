import { useEffect, useState } from 'react'
import { RecordingViewModel } from '../../api/Recording.model'
import { Answer } from '../../model/Answer.model'
import { QuizParams } from '../../model/QuizParams.model'
import { QuizSummary } from '../../model/QuizSummary.model'
import { useQuizFetch } from '../../hooks/useQuizFetch'
import { TaxonViewModel } from '../../model/Taxon.model'
import { QuizQuestionViewModel } from '../../api/QuizQuestion.model'
import { ObservationPhotoViewModel } from '../../api/ObservationPhoto.model'
import { AnswerButtonModel, AnswerButtonState } from '../../model/AnswerButtonState.model'

export type useQuizQuestionsState = {
    question?: QuizQuestionViewModel
    recording?: RecordingViewModel
    photo?: ObservationPhotoViewModel
    birds: TaxonViewModel[]
    showAutocomplete: boolean
    answerButtons: AnswerButtonModel[]
    loading: boolean
    error?: string
    isAnswered: boolean
    nextPhotoEnabled: boolean
    nextRecordingEnabled: boolean
    hasRecordingError: boolean
    isSurrenderEnabled: boolean
    handleBirdSelected: (bird: TaxonViewModel) => void
    handleAnswerClick: (bird: TaxonViewModel) => void
    handleNextPhoto: () => void
    handleNextRecording: () => void
    handleNextQuestion: () => void
    handleFinished: () => void
    handleRecordingError: () => void
    handleSurrender: () => void
}

export default function useQuizQuestions (
    params: QuizParams,
    taxons: TaxonViewModel[],
    onFinished: (summary: QuizSummary) => void): useQuizQuestionsState {
    const [birds, setBirds] = useState<TaxonViewModel[]>([])
    const [currentRecording, setCurrentRecording] = useState<RecordingViewModel>()
    const [currentPhoto, setCurrentPhoto] = useState<ObservationPhotoViewModel>()
    const [currentQuestion, setCurrentQuestion] = useState<QuizQuestionViewModel>()
    const [currentAnswers, setCurrentAswers] = useState<TaxonViewModel[]>([])
    const [answerButtons, setAnswerButtons] = useState<AnswerButtonModel[]>([])
    const [isAnswered, setIsAnswered] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>()
    const [answers, setAnswers] = useState<Answer[]>([])
    const [nextPhotoEnabled, setNextPhotoEnabled] = useState<boolean>(true)
    const [nextRecordingEnabled, setNextRecordingEnabled] = useState<boolean>(true)
    const [hasRecordingError, setHasRecordingError] = useState<boolean>(false)

    const quizFetchStatus = useQuizFetch(params)

    const showAutocomplete = birds.length > 10

    useEffect(() => {
        if (quizFetchStatus.status === 'loading') {
            setLoading(true)
            return
        }

        if (quizFetchStatus.status === 'loaded') {
            console.log('Quiz loaded', quizFetchStatus.value)

            const firstQuestion = quizFetchStatus.value.questions[0]
            setCurrentQuestion(firstQuestion)
            setCurrentRecording(firstQuestion.recordings[0])
            setCurrentPhoto(firstQuestion.photos[0])
            setBirds(taxons.filter(taxon => quizFetchStatus.value.birdIDs.includes(taxon.id)))
            setCurrentAswers([])
            setLoading(false)
        }

        if (quizFetchStatus.status === 'error') {
            setLoading(false)
            console.log(quizFetchStatus.errors)
            setError(quizFetchStatus.errors?.join(', '))
        }
    }, [quizFetchStatus])

    useEffect(() => {
        console.log('currentQuestion', currentQuestion)
    }, [currentQuestion])

    const getAnswerButtonState = (
        questionBirdID: number,
        buttonBird: TaxonViewModel,
        isAnswered: boolean,
        currentAnswers: TaxonViewModel[]): AnswerButtonState => {
        if (currentAnswers.length === 0) return 'enabled'

        if (currentAnswers.some(a => a.id === buttonBird.id)) {
            return buttonBird.id === questionBirdID
                ? 'correct'
                : 'incorrect'
        }

        return isAnswered ? 'disabled' : 'enabled'
    }

    const getIsAnswered = (): boolean => {
        if (params.mode === 'competition' && currentAnswers.length > 0) return true

        if (params.mode === 'education' && currentAnswers.some(t => t.id === currentQuestion?.taxonID)) return true

        if (currentAnswers.length === birds.length) return true

        return false
    }

    useEffect(() => {
        if (currentQuestion == null) return

        const isQuestionAnswered = getIsAnswered()

        setIsAnswered(isQuestionAnswered)

        const source = showAutocomplete ? currentAnswers : birds

        const buttons: AnswerButtonModel[] = source.map(bird => {
            return {
                bird,
                state: getAnswerButtonState(
                    currentQuestion.taxonID,
                    bird,
                    isQuestionAnswered,
                    currentAnswers)
            }
        })

        setAnswerButtons(buttons)
    }, [currentAnswers])

    useEffect(() => {
        if (currentQuestion == null || currentPhoto == null) {
            return
        }

        const index = currentQuestion.photos.indexOf(currentPhoto)

        console.log('currentPhoto', { index, currentPhoto })

        if (index >= currentQuestion.photos.length - 1) {
            console.log(`Not enough photos for ${currentQuestion.taxonID} to show next photo, disabling the button`, { index, total: currentQuestion.photos.length })
            setNextPhotoEnabled(false)
        } else {
            setNextPhotoEnabled(true)
        }
    }, [currentPhoto])

    useEffect(() => {
        if (currentQuestion == null || currentRecording == null) {
            return
        }

        setHasRecordingError(false)

        const index = currentQuestion.recordings.indexOf(currentRecording)

        console.log('currentRecording', { index, currentRecording })

        if (index >= currentQuestion.recordings.length - 1) {
            console.log(`Not enough recordings for ${currentQuestion.taxonID} to show next recording, disabling the button`, { index, total: currentQuestion.recordings.length })
            setNextRecordingEnabled(false)
        } else {
            setNextRecordingEnabled(true)
        }
    }, [currentRecording])

    const handleAnswerClick = (bird: TaxonViewModel): void => {
        const question = currentQuestion as QuizQuestionViewModel

        setCurrentAswers(prev => [...prev, bird])

        const answer = answers.find(a => a.index === question.index)
        if (answer == null) {
            const newAnswer = {
                index: question.index,
                correct: bird.id === question.taxonID,
                bird: birds.find(b => b.id === question.taxonID) as TaxonViewModel,
                pickedBirds: [bird],
                recording: currentRecording as RecordingViewModel
            }

            setAnswers(prevAnswers => [...prevAnswers, newAnswer])
        } else {
            answer.pickedBirds = [...answer.pickedBirds, bird]
        }
    }

    const handleBirdSelected = (bird: TaxonViewModel): void => {
        handleAnswerClick(bird)
    }

    const handleNextPhoto = (): void => {
        if (currentQuestion == null || currentPhoto == null) {
            return
        }

        const currentIndex = currentQuestion.photos.indexOf(currentPhoto)

        setCurrentPhoto(currentQuestion.photos[currentIndex + 1])
    }

    const handleNextRecording = (): void => {
        if (currentQuestion == null || currentRecording == null) {
            return
        }

        const currentIndex = currentQuestion.recordings.indexOf(currentRecording)

        setCurrentRecording(currentQuestion.recordings[currentIndex + 1])
    }

    const handleSurrender = (): void => {
        if (currentQuestion == null || quizFetchStatus.status !== 'loaded') {
            return
        }

        setCurrentAswers(prev => [...prev, birds.find(bird => bird.id === currentQuestion.taxonID) as TaxonViewModel])

        const answer = answers.find(a => a.index === currentQuestion.index)
        if (answer == null) {
            const newAnswer = {
                index: currentQuestion.index,
                correct: false,
                bird: birds.find(b => b.id === currentQuestion.taxonID) as TaxonViewModel,
                pickedBirds: [],
                recording: currentRecording as RecordingViewModel
            }

            setAnswers(prevAnswers => [...prevAnswers, newAnswer])
        }
    }

    const handleNextQuestion = (): void => {
        if (currentQuestion == null || quizFetchStatus.status !== 'loaded') {
            return
        }

        const nextQuestion = quizFetchStatus.value.questions.find(q => q.index === currentQuestion.index + 1)

        setCurrentAswers([])
        setCurrentQuestion(nextQuestion)
        setCurrentRecording(nextQuestion?.recordings[0])
        setCurrentPhoto(nextQuestion?.photos[0])
    }

    const handleFinished = (): void => {
        const summary: QuizSummary = {
            answers: answers
        }

        onFinished(summary)
    }

    return {
        question: currentQuestion,
        recording: currentRecording,
        photo: currentPhoto,
        birds,
        showAutocomplete,
        answerButtons,
        loading,
        error,
        isAnswered,
        nextPhotoEnabled,
        nextRecordingEnabled,
        hasRecordingError,
        isSurrenderEnabled: !isAnswered,
        handleBirdSelected,
        handleAnswerClick,
        handleNextPhoto,
        handleNextRecording,
        handleNextQuestion,
        handleFinished,
        handleRecordingError: () => setHasRecordingError(true),
        handleSurrender
    }
}
