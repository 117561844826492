import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { FilterOptionsState } from '@mui/material'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderOptionState, createFilterOptions } from '@mui/material/Autocomplete'
import React, { ReactElement } from 'react'
import { TaxonViewModel } from '../../model/Taxon.model'

type Props = {
    birds: TaxonViewModel[]
    disabled: boolean
    onBirdSelected: (bird: TaxonViewModel) => void
}

export default function QuizQuestionAutocomplete ({
    birds,
    disabled,
    onBirdSelected
}: Props): ReactElement {
    // const [value, setValue] = useState<TaxonViewModel | null>(null)

    const handleBirdsChange = (
        event: React.ChangeEvent<{}>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<TaxonViewModel>): void => {
        onBirdSelected(value)
    }

    const filterOptions = createFilterOptions({
        limit: 10,
        stringify: (option: TaxonViewModel) => `${option.name}${option.scientificName}`
    })

    const filterOptionsWithMinChar = (options: TaxonViewModel[], state: FilterOptionsState<TaxonViewModel>): TaxonViewModel[] => {
        if (state.inputValue.length < 3) {
            return []
        }

        return filterOptions(options, state)
    }

    return (
        <Autocomplete
            id="tags-standard"
            disabled={disabled}
            options={birds}
            getOptionLabel={(option: TaxonViewModel) => option.name}
            value={null}
            onChange={handleBirdsChange}
            filterOptions={filterOptionsWithMinChar}
            noOptionsText="Zadejte minimálně 3 znaky"
            blurOnSelect={true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Vyberte ptačí druh"
                />
            )}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: TaxonViewModel, state: AutocompleteRenderOptionState) =>
                <Box component="li" {...props} sx={{
                    padding: '4px !important',
                    borderTop: '1px solid lightgray'
                }}>
                    <Box>
                        <Box sx={{ fontSize: 12 }}>{option.name}</Box>
                        <Box sx={{ fontStyle: 'italic', fontSize: 10 }}>{option.scientificName}</Box>
                    </Box>
                </Box>
            }
        />
    )
}
