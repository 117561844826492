import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import { UserQuizResultInsertRequest } from '../model/UserQuizResultInsertRequest.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type UserQuizResultPostStatus = FetchStatus<number>

export const useUserQuizResultPost = (
    request?: UserQuizResultInsertRequest): UserQuizResultPostStatus => {
    const { apiUrl } = useAppConfig()

    return useFetch<number>(
        request != null
            ? urljoin(apiUrl, 'UserQuizResult')
            : undefined,
        'UserQuizResult',
        request
    )
}
