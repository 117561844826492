import urljoin from 'url-join'
import { QuizViewModel } from '../api/Quiz.model'
import { FetchStatus } from '../model/FetchStatus.model'
import { LogoutRequest } from '../providers/LoginProvider'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type LogoutResponseModel = {}

export type LogoutFetchStatus = FetchStatus<LogoutResponseModel>

export const useLogoutFetch = (
    logoutRequest?: LogoutRequest): LogoutFetchStatus => {
    const { apiUrl } = useAppConfig()

    return useFetch<QuizViewModel>(
        logoutRequest != null
            ? urljoin(apiUrl, 'User', 'logout')
            : undefined,
        'Logout',
        {}
    )
}
