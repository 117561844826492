import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import { TaxonViewModel } from '../model/Taxon.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type TaxonsFetchStatus = FetchStatus<TaxonViewModel[]>

export const useTaxonsFetch = (
    placeId?: number,
    languageId?: number): TaxonsFetchStatus => {
    const { apiUrl } = useAppConfig()

    let searchParams: URLSearchParams | undefined

    if (placeId != null && languageId != null) {
        searchParams = new URLSearchParams()
        searchParams.append('placeID', placeId.toString())
        searchParams.append('languageID', languageId.toString())
    }

    return useFetch<TaxonViewModel[]>(
        searchParams != null
            ? urljoin(apiUrl, `Taxons?${searchParams.toString()}`)
            : undefined,
        'Taxons'
    )
}
