import { Box } from '@material-ui/core'
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderOptionState, createFilterOptions } from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { FilterOptionsState } from '@mui/material/useAutocomplete'
import React, { ReactElement } from 'react'
import { TaxonViewModel } from '../../model/Taxon.model'

type Props = {
    allTaxons: TaxonViewModel[]
    taxons: TaxonViewModel[]
    loading: boolean
    onTaxonsChange: (taxons: TaxonViewModel[]) => void
}

const filterOptions = createFilterOptions({
    limit: 10,
    stringify: (option: TaxonViewModel) => `${option.name}${option.scientificName}`
})

const filterOptionsWithMinChar = (options: TaxonViewModel[], state: FilterOptionsState<TaxonViewModel>): TaxonViewModel[] => {
    if (state.inputValue.length < 3) {
        return []
    }

    return filterOptions(options, state)
}

export default function TaxonsAutocomplete ({
    allTaxons,
    taxons,
    loading,
    onTaxonsChange
}: Props): ReactElement {
    const handleChange = (
        event: React.ChangeEvent<{}>,
        value: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<TaxonViewModel>): void => {
        onTaxonsChange(value)
    }

    return (
        <Autocomplete
            multiple
            id="tags-standard"
            options={allTaxons}
            getOptionLabel={(option: TaxonViewModel) => option.name}
            value={taxons}
            loading={loading}
            onChange={handleChange}
            filterOptions={filterOptionsWithMinChar}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Zadejte minimálně 3 znaky"
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Vyberte ptačí druhy, čeledi nebo řády"
                />
            )}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: TaxonViewModel, state: AutocompleteRenderOptionState) =>
                <Box component="li" {...props} sx={{
                    padding: '4px !important',
                    borderTop: '1px solid lightgray'
                }}>
                    <Stack direction="row" alignItems="center">
                        <Box sx={{
                            marginRight: '4px',
                            lineHeight: 0.5
                        }}><img src={option.photoUrl} width="40" height="40" loading='lazy'></img></Box>
                        <Box>
                            <Box sx={{ fontSize: 12 }}>{option.name}</Box>
                            <Box sx={{ fontStyle: 'italic', fontSize: 10 }}>{option.scientificName}</Box>
                        </Box>
                    </Stack>
                </Box>
            }
        />
    )
}
