import urljoin from 'url-join'
import { QuizViewModel } from '../api/Quiz.model'
import { QuizRequest } from '../api/QuizRequest.model'
import { FetchStatus } from '../model/FetchStatus.model'
import { QuizParams } from '../model/QuizParams.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type QuizFetchStatus = FetchStatus<QuizViewModel>

export const useQuizFetch = (
    quizParams?: QuizParams): QuizFetchStatus => {
    const { apiUrl } = useAppConfig()

    const quizRequest: QuizRequest | undefined = quizParams != null
        ? {
            taxonIDs: quizParams.taxons.map(bird => bird.id),
            questionCount: quizParams.questionCount,
            mode: quizParams.mode,
            quizType: quizParams.quizType,
            soundType: quizParams.soundType
        }
        : undefined

    return useFetch<QuizViewModel>(
        quizRequest != null
            ? urljoin(apiUrl, 'Quiz')
            : undefined,
        'Quiz',
        quizRequest
    )
}
