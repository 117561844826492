import { CircularProgress, Stack, Card, CardHeader, CardContent, CardActions, Alert } from '@mui/material'
import React, { ReactElement } from 'react'
import { QuizParams } from '../../model/QuizParams.model'
import { QuizSummary } from '../../model/QuizSummary.model'
import useQuizQuestions from './useQuizQuestions'
import { Box } from '@mui/system'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Grid from '@mui/material/Grid'
import QuizQuestionActionButtons from './QuizQuestionActionButtons'
import QuizQuestionAnswerButtons from './QuizQuestionAnswerButtons'
import QuizQuestionPhoto from './QuizQuestionPhoto'
import QuizQuestionRecording from './QuizQuestionRecording'
import { TaxonViewModel } from '../../model/Taxon.model'
import QuizQuestionAutocomplete from './QuizQuestionAutocomplete'

export type QuizQuestionsProps = {
    params: QuizParams
    taxons: TaxonViewModel[]
    onRepeatQuiz: () => void
    onFinished: (summary: QuizSummary) => void
}

export default function QuizQuestions ({ params, taxons, onRepeatQuiz, onFinished }: QuizQuestionsProps): ReactElement {
    const {
        question,
        recording,
        photo,
        birds,
        showAutocomplete,
        answerButtons,
        loading,
        error,
        isAnswered,
        nextPhotoEnabled,
        nextRecordingEnabled,
        hasRecordingError,
        isSurrenderEnabled,
        handleBirdSelected,
        handleAnswerClick,
        handleNextPhoto,
        handleNextRecording,
        handleNextQuestion,
        handleFinished,
        handleRecordingError,
        handleSurrender
    } = useQuizQuestions(params, taxons, onFinished)

    if (loading) {
        return (<Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress variant="indeterminate" />
            <Box sx={{ marginLeft: '1rem' }}>Nahrávám kvíz...</Box>
        </Box >)
    }

    if (error != null) {
        return (
            <Alert severity="error">
                <p>Nastala chyba při vytváření kvízu. Zkuste prosím vytvořit kvíz znovu s jinými parametry.</p>
                <p>Podrobnosti: {error}</p>
                <p>Parametry: <pre>{JSON.stringify(params, null, 2)}</pre></p>
            </Alert>)
    }

    // if (insufficientBirdRecordings.length > 0) {
    //     return (<div>
    //         <p>Pro tyto druhy bohužel není k dispozici dostatečný počet nahrávek, upravte prosím parametry kvízu a zkuste to znovu</p>
    //         <ul>
    //             {insufficientBirdRecordings.map(br => <li key={br.bird.xenoCantoName}>{br.bird.czechName} ({br.recordings.length})</li>)}
    //         </ul>
    //         <div>
    //             <Button
    //                 onClick={onRepeatQuiz}
    //                 variant="contained"
    //                 color="primary">Upravit kvíz</Button>
    //         </div>
    //     </div>)
    // }

    const isLastQuestion = question?.index === params.questionCount - 1
    const isEducation = params.mode === 'education'

    const actionButtons = <QuizQuestionActionButtons
        isSurrenderEnabled={isSurrenderEnabled}
        isLastQuestion={isLastQuestion}
        isAnswered={isAnswered}
        onSurrender={handleSurrender}
        onNextQuestion={handleNextQuestion}
        onFinished={handleFinished} />

    return (
        <Card>
            <CardHeader
                title={`Otázka ${(question?.index ?? 0) + 1}/${params.questionCount}`}
                action={actionButtons}>
            </CardHeader>

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <Stack direction="column" sx={{ width: '500px' }} spacing={4}>
                            {photo != null &&
                                <QuizQuestionPhoto
                                    photo={photo}
                                    isEducation={isEducation}
                                    nextPhotoEnabled={nextPhotoEnabled}
                                    handleNextPhoto={handleNextPhoto} />
                            }

                            {recording != null &&
                                <QuizQuestionRecording
                                    recording={recording}
                                    isEducation={isEducation}
                                    nextRecordingEnabled={nextRecordingEnabled}
                                    hasError={hasRecordingError}
                                    handleNextRecording={handleNextRecording}
                                    onError={handleRecordingError} />
                            }
                        </Stack>
                    </Grid>

                    <Grid item md={4}>
                        <Stack direction="column" spacing={2}>
                            {showAutocomplete &&
                                <QuizQuestionAutocomplete
                                    birds={birds}
                                    disabled={isAnswered}
                                    onBirdSelected={handleBirdSelected} />
                            }
                            <Box sx={{
                                textAlign: 'center'
                            }}>
                                <QuizQuestionAnswerButtons
                                    answerButtons={answerButtons}
                                    handleAnswerClick={handleAnswerClick} />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions>
                <Stack sx={{ width: '100%' }}
                    direction="row"
                    justifyContent="flex-end">{actionButtons}
                </Stack>
            </CardActions>
        </Card>)
}
