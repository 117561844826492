import Button from '@mui/material/Button'
import React, { ReactElement, useEffect, useState } from 'react'
import { useLoginContext } from '../../providers/useLoginContext'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'
import AccountMenu from './AccountMenu'
import { useAccountFetch } from '../../hooks/useAccountFetch'

export type ToolbarButtonProps = {
    onNewQuiz: () => void
    onAbout: () => void
}

export default function ToolbarButtons ({
    onNewQuiz,
    onAbout
}: ToolbarButtonProps): ReactElement {
    const [requestAccount, setRequestAccount] = useState<boolean>(false)

    const { user, showLoginForm, logout } = useLoginContext()

    const accountFetchStatus = useAccountFetch(requestAccount)

    const handleAccount = (): void => {
        setRequestAccount(true)
    }

    useEffect(() => {
        console.log('accountFetchStatus', accountFetchStatus)
    }, [accountFetchStatus])

    return (
        <>
            <Button onClick={onNewQuiz} color="inherit">Nový kvíz</Button>

            <IconButton onClick={onAbout} color="inherit">
                <InfoIcon></InfoIcon>
            </IconButton>

            {user == null &&
                <Button
                    color="inherit"
                    onClick={showLoginForm}>Přihlásit</Button>
            }

            {user != null &&
                <AccountMenu
                    user={user}
                    onAccount={handleAccount}
                    onLogout={logout} />
            }
        </>
    )
}
