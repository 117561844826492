import React, { ReactElement } from 'react'
import { Answer } from '../../model/Answer.model'
import { Box } from '@mui/system'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

type Props = {
    answers: Answer[]
}

export default function QuizResultsTable ({
    answers
}: Props): ReactElement {
    return (<>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '75px' }}></TableCell>
            <TableCell>Správná odpověď</TableCell>
            <TableCell>Vaše odpovědi</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answers.map(answer => (
            <TableRow
              key={answer.index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: answer.correct ? '#2e7d324a' : '#ff00003d'
             }}
            >
              <TableCell>
                <img src={answer.bird.photoUrl} />
              </TableCell>
              <TableCell>{answer.bird.name}</TableCell>
              <TableCell>
                {answer.pickedBirds.map((pickedBird, index) => {
                    const correct = pickedBird.id === answer.bird.id

                    return <Box key={pickedBird.id} sx={{
                        color: correct ? 'green' : 'red',
                        fontWeight: index === 0 ? 'bold' : 'normal'
                    }}>{pickedBird.name}
                    </Box>
                })
                    }
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>)
}
