import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type AccountResponseModel = string

export type AccountFetchStatus = FetchStatus<AccountResponseModel>

export const useAccountFetch = (
    request: boolean): AccountFetchStatus => {
    const { apiUrl } = useAppConfig()

    return useFetch<string>(
        request
            ? urljoin(apiUrl, 'User', 'account')
            : undefined,
        'Account'
    )
}
