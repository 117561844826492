import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import { UserTaxonSelectionInsertRequest } from '../model/UserTaxonSelectionInsertRequest.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type UserTaxonSelectionPostStatus = FetchStatus<number>

export const useUserTaxonSelectionPost = (
    request?: UserTaxonSelectionInsertRequest): UserTaxonSelectionPostStatus => {
    const { apiUrl } = useAppConfig()

    return useFetch<number>(
        request != null
            ? urljoin(apiUrl, 'UserTaxonSelection')
            : undefined,
        'UserTaxonSelection',
        request
    )
}
