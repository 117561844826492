import axios, { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { FetchStatus } from '../model/FetchStatus.model'

export const getFetchStatusValueOrUndefined = <T> (fetchStatus: FetchStatus<T>): T | undefined => {
  return fetchStatus.status === 'loaded' ? fetchStatus.value : undefined
}

export const getFetchStatusCurrentOrPreviousValueOrUndefined = <T> (fetchStatus: FetchStatus<T>): T | undefined => {
  return fetchStatus.status === 'loaded'
    ? fetchStatus.value
    : fetchStatus.status === 'loading'
      ? fetchStatus.prevValue
      : undefined
}

export const getLoadedFetchStatus = <T> (value: T): FetchStatus<T> => {
  return {
    status: 'loaded',
    value
  }
}

export const useFetch = <T> (
  url: string | undefined,
  entityName: string,
  postData?: Object | (() => Object)): FetchStatus<T> => {
  const [status, setStatus] = useState<FetchStatus<T>>({ status: 'pending' })

  const { enqueueSnackbar } = useSnackbar()

  const getErrorDetails = (error: any): string => {
    if (axios.isAxiosError(error)) {
      const errorData = (error.response?.data as any).error as string

      return `${error.message}: ${errorData}`
    }

    return error as string
  }

  useEffect(() => {
    const abortController = new AbortController()

    const fetch = (url: string): void => {
      setStatus(prev => {
        return {
          status: 'loading',
          prevValue: prev.status === 'loaded'
            ? prev.value
            : prev.status === 'loading'
              ? prev.prevValue
              : undefined
        }
      })

      const httpClient = axios
      const requestUrl = url
      const usePostData: Object | undefined = typeof postData === 'function' ? postData() : postData

      const axiosResponse: Promise<AxiosResponse<any>> = usePostData != null
        ? httpClient.post(
          requestUrl,
          usePostData,
          {
            signal: abortController.signal,
            withCredentials: true
          }
        )
        : httpClient.get(
          requestUrl,
          {
            signal: abortController.signal,
            withCredentials: true
          }
        )

      axiosResponse.then((result) => {
        setStatus({ status: 'loaded', value: result.data as T })
      }).catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled in useFetch:', requestUrl)
        } else {
          setStatus({ status: 'error', errors: [getErrorDetails(error)] })
          enqueueSnackbar(`Failed to load data [${entityName}]`, { variant: 'error' })
        }
      })
    }

    if (url != null) {
      fetch(url)
    } else {
      setStatus({ status: 'pending' })
    }

    return () => abortController.abort()
  }, [url])

  return status
}
