import Button from '@mui/material/Button'
import React, { ReactElement } from 'react'

type QuizQuestionActionButtonsProps = {
    isLastQuestion: boolean
    isAnswered: boolean
    isSurrenderEnabled: boolean
    onSurrender: () => void
    onNextQuestion: () => void
    onFinished: () => void
}

export default function QuizQuestionActionButtons ({
    isLastQuestion,
    isAnswered,
    isSurrenderEnabled,
    onSurrender,
    onNextQuestion,
    onFinished
}: QuizQuestionActionButtonsProps): ReactElement {
    return (
        <>
            {isSurrenderEnabled && <Button
                onClick={onSurrender}
                variant="text"
                sx={{
                    marginRight: '1rem'
                }}>Nevím</Button>
            }

            {!isLastQuestion && <Button
                onClick={onNextQuestion}
                disabled={!isAnswered}
                variant="contained"
                color="primary">Další otázka</Button>
            }

            {isLastQuestion && <Button
                onClick={onFinished}
                disabled={!isAnswered}
                variant="contained"
                color="primary">Zobrazit výsledky</Button>
            }
        </>
    )
}
