import Box from '@mui/material/Box'
import React, { ReactElement } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import LoopIcon from '@mui/icons-material/Loop'
import { RecordingViewModel } from '../../api/Recording.model'
import RecordingPlayer from '../RecordingPlayer/RecordingPlayer'
import IconButton from '@mui/material/IconButton'
import Alert from '@mui/material/Alert'

type Props = {
    recording: RecordingViewModel
    isEducation: boolean
    nextRecordingEnabled: boolean
    hasError: boolean
    handleNextRecording: () => void
    onError: () => void
}

export default function QuizQuestionRecording ({
    recording,
    isEducation,
    nextRecordingEnabled,
    hasError,
    handleNextRecording,
    onError
}: Props): ReactElement {
    return (
        <Box>
            <Box>
                <RecordingPlayer
                    recording={recording}
                    autoPlay={true}
                    onError={onError}
                ></RecordingPlayer>
                {hasError &&
                    <Alert severity="error">
                        Nastala chyba v přehrávači.&nbsp;
                        <a href={recording?.file}>Stáhnout nahrávku</a>
                    </Alert>
                }
            </Box>
            <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginTop: '0.5rem', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }} /> {recording.recordistName}
                </Box>
                {isEducation &&
                    <Box>
                        <IconButton
                            size="small"
                            disabled={!nextRecordingEnabled}
                            onClick={handleNextRecording}
                            title="Zkusit jinou nahrávku">
                            <LoopIcon fontSize='small' />
                        </IconButton>
                    </Box>
                }
            </Box>
            <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PlaceIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }} /> {recording.locality}, {recording.country}
                </Box>
                <Box>
                    <a
                        href={recording.url}
                        target="_blank"
                        rel="noreferrer">Podrobnosti</a>
                </Box>
            </Box>
        </Box>
    )
}
