import React, { ReactElement, useEffect, useState } from 'react'
import CircularProgress, { CircularProgressProps, CircularProgressPropsColorOverrides } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { OverridableStringUnion } from '@mui/types'

type Props = CircularProgressProps & { value: number }

function getColor (value: number): OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
    CircularProgressPropsColorOverrides
> {
    if (value < 50) return 'error'

    if (value < 80) return 'warning'

    return 'success'
}

const size = 60
const thickness = 5
const secColor = '#EEE'

const progressSx = {
    borderRadius: '50%',
    boxShadow: `inset 0 0 0 ${thickness / 44 * size}px ${secColor}`
}

export default function Gauge ({
    value
}: Props): ReactElement {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= value ? value : Math.min(value, prevProgress + 5)))
      }, 100)
      return () => {
        clearInterval(timer)
      }
    }, [])

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={progress}
                color={getColor(value)}
                thickness={5}
                size={60}
                sx={progressSx}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontSize="0.9rem"
                >{`${Math.round(progress)}%`}</Typography>
            </Box>
        </Box>
    )
}
