import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import { LoginRequest } from '../model/LoginRequest.model'
import { User } from '../model/User.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type LoginFetchStatus = FetchStatus<User>

export const useLoginFetch = (
    loginRequest?: LoginRequest): LoginFetchStatus => {
    const { apiUrl } = useAppConfig()

    return useFetch<User>(
        loginRequest != null
            ? urljoin(apiUrl, 'User', 'login')
            : undefined,
        'Login',
        loginRequest
    )
}
