import React, { ReactElement, useState } from 'react'
import './App.scss'
import Quiz from './components/Quiz/Quiz'
import About from './components/About/About'
import { Box, ThemeProvider } from '@mui/system'
import { AppBar, Container, createTheme, Toolbar, Typography } from '@mui/material'
import LoginProvider from './providers/LoginProvider'
import ToolbarButtons from './components/Toolbar/ToolbarButtons'

type AppView = 'quiz' | 'about'

function App (): ReactElement {
  const [appView, setAppView] = useState<AppView>('quiz')
  const [resetToken, setResetToken] = useState<string>()

  const handleNewQuiz = (): void => {
    setResetToken(new Date().getTime().toString())
    setAppView('quiz')
  }

  const handleAbout = (): void => {
    setAppView('about')
  }

  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <Box sx={{
          flexGrow: 1
        }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" sx={{
                flexGrow: 1,
                margin: theme.spacing(1),
                color: 'white'
              }}>
                Kdopak to zpívá?
              </Typography>

              <ToolbarButtons
                onNewQuiz={handleNewQuiz}
                onAbout={handleAbout} />
            </Toolbar>
          </AppBar>

          <Container sx={{
            padding: '1rem'
          }}
            maxWidth="md">
            {appView === 'about' && <About />}
            {appView === 'quiz' && <Quiz resetToken={resetToken} />}
          </Container>
        </Box>
      </LoginProvider>
    </ThemeProvider >
  )
}

export default App
