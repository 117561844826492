import urljoin from 'url-join'
import { FetchStatus } from '../model/FetchStatus.model'
import { UserTaxonSelectionViewModel } from '../model/UserTaxonSelection.model'
import useAppConfig from './useAppConfig'
import { useFetch } from './useFetch'

export type UserTaxonSelectionsFetchStatus = FetchStatus<UserTaxonSelectionViewModel[]>

export const useUserTaxonSelectionsFetch = (
    placeId: number,
    languageId: number,
    query?: string,
    onlyMine?: boolean): UserTaxonSelectionsFetchStatus => {
    const { apiUrl } = useAppConfig()

    let searchParams: URLSearchParams | undefined

    if (query != null && onlyMine != null) {
        searchParams = new URLSearchParams()
        searchParams.append('query', query)
        searchParams.append('onlyMine', onlyMine.toString())
        searchParams.append('placeId', placeId.toString())
        searchParams.append('languageId', languageId.toString())
    }

    return useFetch<UserTaxonSelectionViewModel[]>(
        searchParams != null
            ? urljoin(apiUrl, `UserTaxonSelection?${searchParams.toString()}`)
            : undefined,
        'UserTaxonSelection'
    )
}
