import React, { FormEvent, ReactElement, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Alert, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material'

export type UserTaxonSelectionSaveDialogProps = {
    open: boolean
    error?: string
    onSave: (name: string, isPublic: boolean) => void
    onClose: () => void
}

export default function UserTaxonSelectionSaveDialog ({
    open,
    error,
    onSave,
    onClose
}: UserTaxonSelectionSaveDialogProps): ReactElement {
    const [name, setName] = useState<string>('')
    const [isPublic, setIsPublic] = useState<boolean>(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault()

        onSave(name, isPublic)

        clear()
    }

    const clear = (): void => {
        setName('')
        setIsPublic(false)
    }

    const handleClose = (): void => {
        clear()
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth>
            <DialogTitle>Uložit výběr</DialogTitle>
            <form onSubmit={(e) => handleSubmit(e)}>
                <DialogContent>
                    <Stack direction="column" spacing={1} sx={{ marginTop: '1rem' }}>
                        {error != null &&
                            <Alert severity="error">{error}</Alert>
                        }

                        <TextField
                            autoFocus
                            label="Název"
                            type="text"
                            name="name"
                            value={name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setName(event.target.value)
                            }}
                            fullWidth
                            required
                            inputProps={{ maxLength: 50 }}
                            variant="standard"
                        />

                        <FormControlLabel control={<Checkbox
                            value={isPublic}
                            onChange={(e, checked) => setIsPublic(checked)}
                        />} label="Veřejný" />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Zavřít</Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary">Uložit</Button>
                </DialogActions>
            </form>
        </Dialog >
    )
}
