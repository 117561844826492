import { useContext } from 'react'
import { LoginContext, LoginContextValue } from './LoginProvider'

export const useLoginContext = (): LoginContextValue => {
    const context = useContext(LoginContext)

    if (context == null) {
        throw Error('LoginContext not found')
    }

    return context
}
