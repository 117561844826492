import Box from '@mui/material/Box'
import React, { ReactElement } from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import LoopIcon from '@mui/icons-material/Loop'
import { ObservationPhotoViewModel } from '../../api/ObservationPhoto.model'
import IconButton from '@mui/material/IconButton'

type Props = {
    photo: ObservationPhotoViewModel
    isEducation: boolean
    nextPhotoEnabled: boolean
    handleNextPhoto: () => void
}

export default function QuizQuestionPhoto ({
    photo,
    isEducation,
    nextPhotoEnabled,
    handleNextPhoto
}: Props): ReactElement {
    return (
        <Box>
            <Box sx={{ textAlign: 'center' }}>
                <InnerImageZoom
                    src={photo.url.replace('square', 'medium')}
                    zoomSrc={photo.url.replace('square', 'large')}
                />
            </Box>
            <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PersonIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }} />{photo.attribution}
                </Box>
                {isEducation &&
                    <Box>
                        <IconButton
                            size="small"
                            disabled={!nextPhotoEnabled}
                            onClick={handleNextPhoto}
                            title="Zkusit jinou fotku">
                            <LoopIcon fontSize='small' />
                        </IconButton>
                    </Box>
                }
            </Box>
            <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PlaceIcon
                        fontSize="small"
                        sx={{ marginRight: '0.25rem' }} /> {photo.placeGuess}
                </Box>
                <Box>
                    <a
                        href={photo.observationUrl}
                        target="_blank"
                        rel="noreferrer">Podrobnosti</a>
                </Box></Box>
        </Box>
    )
}
